import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
// import image from "../assets/photo.webp";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast, { Toaster } from "react-hot-toast";

// animation develop
import send from "../../src/assets/animation/send-2.json";
import Lottie from "lottie-react";
// icons social
import { FaGithub, FaLinkedin, FaBehance } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaCopy } from "react-icons/fa6";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Esquema de validación utilizando Zod
const ContactFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  message: z.string().min(1, "Message is required"),
});

const Contact = () => {
  // const notify = () => toast("Message sent succesfully!");

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(ContactFormSchema),
    mode: "onChange",
  });
  const [show, setShow] = useState(false);
  const textToCopy = "imadniabou@gmail.com"; // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      // Envía el formulario usando EmailJS
      await emailjs.send(
        "service_yqio9iy", // Reemplaza con tu Service ID de EmailJS
        "template_hdyzzf1", // Reemplaza con tu Template ID de EmailJS
        data,
        "i2eAaAPjeE0fdbZB7" // Reemplaza con tu User ID de EmailJS
      );
      toast.success("Message submitted");

      // Resetea los campos después de un envío exitoso
      resetField("name");
      resetField("email");
      resetField("message");
    } catch (error) {
      console.error("Failed to send email:", error);
      toast.error("An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_yqio9iy", "template_hdyzzf1", form.current, {
        publicKey: "i2eAaAPjeE0fdbZB7",
      })
      .then(
        () => {},
        (error) => {
          // console.log('FAILED...', error.text);
          toast.error("something went wrong");
        }
      );
  };
  return (
    <section className="section py-16 lg:section " id="contact">
      <div className="white-gradient10" />
      <div className="white-gradient11" />
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <div className="flex-1 max-w-[480px] fadeInUp ">
            <div>
              <h4 className="text-xl uppercase text-accent-3 font-primary font-medium tracking-wider">
                Get in touch
              </h4>
              <h2 className="mb-4 text-[45px] lg:text-[80px] leading-none mb-12">
                Let´s
                <br /> Work
                <br />
                Together!
              </h2>
              <div className="animation flex items-center fadeInUp ">
                {/* animation develop */}
                <Lottie
                  className="relative left-14"
                  style={{ height: 260, width: 240 }}
                  animationData={send}
                />
              </div>
            </div>
          </div>
          <form
            ref={form}
            onSubmit={handleSubmit(onSubmit)}
            className="form glass flex-1 rounded-3xl flex flex-col gap-y-6 pb-24 p-6 items-start fadeInDown "
          >
            <input
              type="text"
              name="user_name"
              className={`bg-transparent border-b border-white/40 p-4 rounded-xl py-3 outline-none w-full placeholder:text-white/70 focus:border-[#2f50ff] transition-all ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="Your name"
              {...register("name")}
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
            )}

            <input
              type="email"
              name="user_email"
              className={`bg-transparent border-b border-white/40 rounded-xl p-4  py-3 outline-none w-full placeholder:text-white/70 focus:border-[#2f50ff] transition-all ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
              {...register("email")}
              placeholder="Your email"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}

            <textarea
              name="message"
              className={`bg-transparent border-b border-white/40 rounded-xl py-12 p-4  outline-none w-full placeholder:text-white/70 focus:border-[#2f50ff] transition-all resize-none mb-12 ${
                errors.message ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="Your message"
              {...register("message")}
            />
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">
                {errors.message.message}
              </p>
            )}

            <button
              type="submit"
              value="Send"
              className={`btn-contact rounded-2xl  cursor-pointer ${
                !isValid || isLoading ? "opacity-50 cursor-not-allowed" : "btn"
              }`}
              disabled={!isValid || isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </form>
          <div>
            <Toaster />
          </div>
        </div>
        <div className="flex flex-col ">
          {/* socials */}
          <div
            className="flex text-[36px] p-10 gap-x-6 mx-auto lg:mx-0 fadeInUp"
            id="social"
          >
            <a
              href="https://www.linkedin.com/in/imad-niabou/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://github.com/ImadNiabou"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
            <a
              href="https://www.behance.net/imadniabou1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaBehance />
            </a>
            <div className="flex gap-2 align-middle">
              <button onMouseEnter={() => setShow(!show)}>
                {
                  <a
                    href="mailto:imadniabou@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoMdMail />
                  </a>
                }
              </button>
              {show && (
                <div className="flex gap-1">
                  <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                    <button className="text-[16px] text-white/60">
                      <FaCopy className="hover:text-[#2f5cff] transition-all duration-300" />
                    </button>
                  </CopyToClipboard>
                  {copyStatus && (
                    <p className="text-sm text-accent-3 align-middle">
                      Mail copied!
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className=" px-10 mb-14 mx-auto lg:mx-0 flex">
            <p>🚀 Imad Niabou &copy; 2024</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
